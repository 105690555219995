import React from "react";
import { css } from "@emotion/css";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { Highlight } from "react-instantsearch-dom";
import { MinistryContext } from "@/context/MinistryContext";
import { slugify } from "@/helpers";

function SearchResult({ hit, layout }) {
  const [parent, setParent] = React.useState(null);
  const { findMinistry, findPage, findContent } =
    React.useContext(MinistryContext);

  const resultType = React.useMemo(() => {
    return hit.objectID.split("\\")[2].split("::")[0];
  }, [hit]);

  const resultLink = React.useMemo(() => {
    setParent(null);

    if (resultType !== "MinistryCategory" && hit.ministry) {
      const ministry = findMinistry(hit.ministry.id);
      setParent(ministry);
    }

    if (hit?.link) return hit.link;

    if (hit?.contentable && hit?.contentable?.ministry_id) {
      const ministry = findMinistry(hit.contentable.ministry_id) || false;
      const page = findPage(hit.contentable.id, hit.contentable.ministry_id) || false;

      if (ministry && page) {
        setParent(ministry);

        if (resultType === "Content") {
          const content = findContent(hit.id, page.id, ministry.id);
          if (content) return `${page.link}#${slugify(content?.title)}`;
        }

        if (resultType) {
        }

        return page.link;
      }
    }

    if (hit?.ministry_id || hit?.calendarable_id) {
      const ministry = findMinistry(hit?.ministry_id ? hit.ministry_id : hit?.calendarable_id);
      if (ministry) setParent(ministry);
    }

    if (resultType === "Calendar") {
      console.log({hit});
    }

    if (parent && (resultType === "Calendar" || resultType === "Event")) {
      return parent.link;
    }

    return false;
  }, [hit]); // eslint-disable-line react-hooks/exhaustive-deps

  if (
    layout === "small" &&
    (!resultLink ||
      resultType === "Link" ||
      resultType === "Event" ||
      resultType === "EventCategory" ||
      resultType === "Calendar" ||
      !hit.is_published)
  ) {
    return null;
  }

  const LinkWrap = ({ hit, className, to, children }) => {
    if (resultType === "Link") {
      return (
        <a
          className={classNames(className)}
          href={to}
          target="_blank"
          rel="noopener noreferrer"
        >
          {children}
        </a>
      );
    }

    return (
      <Link className={className} to={to}>
        {children}
      </Link>
    );
  };
  if (layout === "full")
    return (
      <LinkWrap to={resultLink}>
        {parent && (
          <span className="text-sm">
            {parent?.ministries_category?.title ? `${parent?.ministries_category?.title} - ` : ''}{parent.title}
          </span>

        )}
        <h5 className={classNames("text-gray-800 mb-0", css`
          .ais-Highlight-highlighted {
            background-color: var(--color-light-blue-300);
          }
        `)}>
          <Highlight hit={hit} attribute="title" />
        </h5>
        <small
          className={classNames(
            "block uppercase font-bold text-gray-500 tracking-wider",
            css`
              font-size: 0.7rem;
            `
          )}
        >
          {resultType.replace("MinistryCategory", "Ministry Category")}
        </small>
        <p className={classNames("text-gray-800 mb-0", css`
          .ais-Highlight-highlighted {
            background-color: var(--color-light-blue-300);
          }
        `)}>
          <Highlight hit={hit} attribute="description" />
        </p>

        <span className="text-lightBlue-500 underline">{resultType === "Link" ? '' : window.location.origin}{resultLink}</span>
      </LinkWrap>
    );

  return (
    <LinkWrap className="px-6 py-2 block hover:bg-gray-100" to={resultLink}>
      <strong className="text-gray-800">
        {Array.isArray(hit?.title) ? <div>{hit.title}</div> : <Highlight hit={hit} attribute="title" />}
      </strong>
      <small
        className={classNames(
          "block uppercase font-bold text-gray-500 tracking-wider",
          css`
            font-size: 0.7rem;
          `
        )}
      >
        {parent && <span>{parent.title} / </span>}
        {resultType.replace("MinistryCategory", "Ministry Category")}
      </small>
    </LinkWrap>
  );
}

SearchResult.defaultProps = {
  layout: "small",
};

export default React.memo(SearchResult);
