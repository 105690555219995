import React from "react";
import classNames from "classnames";
import { css } from "@emotion/css";
import { NavLink } from "react-router-dom";
import Loader from "@/components/Loader";
import { MinistryContext } from "@/context/MinistryContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const HeaderNav = function ({ isOpen, setIsOpen }) {
  const { allDataLoaded, allMinistryCategoryData } = React.useContext(
    MinistryContext
  );

  // Currently this function is unnecessary, but allows
  // us the option to delay the closing of the nav for user XP.
  const delayNavClose = function () {
    setTimeout(() => {
      return setIsOpen(false);
    }, 0);
  };

  return (
    <>
      <nav
        data-cy="header-nav"
        className={classNames(
          "inset-0 fixed bg-gray-100 z-10 py-10 px-16 overflow-hidden overflow-y-auto z-50",
          {
            "opacity-0 shadow-none pointer-events-none": !isOpen,
          },
          css``
        )}
      >
        <div className="container">
          {allDataLoaded ? (
            <div className="w-full flex flex-col lg:flex-row flex-wrap gap-12 lg:gap-6 font-medium text-base lg:text-xs text-gray-900">
              {allMinistryCategoryData.data.map((m, i) => (
                <ul
                  data-cy="header-nav-menu-items"
                  key={i}
                  className={classNames(
                    "flex-1 flex flex-col gap-2",
                    css`
                      .active {
                        border-bottom-color: ${m.color_code};
                      }
                    `
                  )}
                >
                  <li>
                    <strong
                      className="block mb-1 font-medium text-blue-500 text-2xl lg:text-sm"
                      onClick={delayNavClose}
                    >
                      <NavLink
                        exact={true}
                        activeClassName="border-b-2 border-lightBlue-500 active"
                        to={m.link}
                      >
                        {m.title}
                      </NavLink>
                    </strong>
                  </li>
                  {m.ministries.map((mm, ii) => (
                    <li key={ii} onClick={delayNavClose}>
                      <NavLink
                        exact={true}
                        activeClassName="border-b-2 border-lightBlue-500 active"
                        to={mm.link}
                      >
                        {mm.title}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              ))}
            </div>
          ) : (
            <Loader color="var(--color-gray-500)" />
          )}
        </div>
      </nav>
      <FontAwesomeIcon
        size="2x"
        className={classNames("fixed bottom-6 right-6 z-50 transition", {
          "opacity-0 pointer-events-none": !isOpen,
        })}
        onClick={() => setIsOpen(false)}
        icon={["far", "times"]}
      />
    </>
  );
};

export default React.memo(HeaderNav);
