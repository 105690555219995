import React from "react";

function Logo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 36.1 61.44"
      tabIndex="-1"
      style={{
        width: "auto",
        height: "50px",
      }}
      data-cy="logo"
      className="text-blue-500 focus:outline-none"
    >
      <title>Gateway Church</title>
      <path
        d="M0,61.44H36.14V21.5c-.63,1.68-1.44,3.65-2.33,5.86A124.4,124.4,0,0,1,26,43.55C20.16,53.91,14.87,57.89,10.83,57.89c-2.59,0-5.82,0-8.93-3.41A10.3,10.3,0,0,1,0,51.28ZM24.94,19.08c5.9-3.06,9.73-3.76,11.2-3.91V0H25.79C14.21,9.57,5,30.53,5,44.21c0,7.1,1.6,11.7,5,11.7,2.86,0,5.55-3.05,10.42-11.19,6.17-10.3,8.12-16.61,8.79-20.1,0-.11.06-.29.1-.51s0-.23,0-.4a.62.62,0,0,0-.11-.36.36.36,0,0,0-.46,0,66,66,0,0,0-5.88,3.74,38,38,0,0,0-7.1,6.37c-.39.47-1,.62-1.19-.71-.14-.94-.5-3.41-.69-4.76a2.34,2.34,0,0,1,.72-2.3A56.24,56.24,0,0,1,24.94,19.1M15.75,0H0V29.91A69.2,69.2,0,0,1,15.75,0"
        fill="currentColor"
      ></path>
    </svg>
  );
}

export default React.memo(Logo);
