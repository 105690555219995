const settings = {
  apiBase: "https://playbook.gatewaypeople.com/api",
  backendURL: "https://playbook.gatewaypeople.com/nova/login",
  useFakeData: false,
  s3Url: "https://cf1.gatewaypeople.com",
  notFoundPath: "/whoops",
  googleAnalyticsID: "G-3D59NNQRDX",
};

export default settings;
