import React, { Suspense } from "react";
import {
  Switch,
  Route,
  Redirect,
  useLocation,
  useHistory,
} from "react-router-dom";
import { Security, LoginCallback } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import Header from "@/components/Header/Header";
import Footer from "@/components/Footer";
import { HeaderProvider } from "@/context/HeaderContext";
import { MinistryProvider } from "@/context/MinistryContext";
import { QueryClient, QueryClientProvider } from "react-query";
import { AnimatePresence } from "framer-motion";
import PageLoader from "@/components/Loaders/PageLoader";
import settings from "@/settings";

const queryClient = new QueryClient();

// Views
const NotFound = React.lazy(() => import("@/views/404"));
const Home = React.lazy(() => import("@/views/Home"));
const MinistryCategory = React.lazy(() => import("@/views/MinistryCategory"));
const Ministry = React.lazy(() => import("@/views/Ministry"));
const MinistrySubpage = React.lazy(() => import("@/views/MinistrySubpage"));
const CampusCalendar = React.lazy(() => import("@/views/CampusCalendar"));
const SearchPage = React.lazy(() => import("@/views/Search"));

const auth = new OktaAuth({
  issuer: "https://gwp.oktapreview.com",
  clientId: "0oa1n1z6o7LnIOI5q1d7",
  redirectUri: window.location.origin + "/login/callback",
});

function App() {
  const location = useLocation();
  const history = useHistory();

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    return history.replace(
      toRelativeUrl(originalUri || "/", window.location.origin)
    );
  };

  // Remove loading class from body
  React.useEffect(() => {
    document.querySelector("body").classList.remove("loading");
  }, []);

  // Reset scroll position between route
  React.useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {}, 500);
  }, [location]);

  return (
    <Security oktaAuth={auth} restoreOriginalUri={restoreOriginalUri}>
      <QueryClientProvider client={queryClient}>
        <HeaderProvider>
          <div className="flex flex-col min-h-screen">
            <MinistryProvider>
              <Header />
            </MinistryProvider>
            <main className="flex-1">
              <AnimatePresence exitBeforeEnter>
                <Switch location={location} key={location.pathname}>
                  <Route path="/login/callback" component={LoginCallback} />
                  <Route path={settings.notFoundPath}>
                    <Suspense fallback={<PageLoader />}>
                      <NotFound />
                    </Suspense>
                  </Route>
                  <Route path="/search/:search?">
                    <Suspense fallback={<PageLoader />}>
                      <MinistryProvider>
                        <SearchPage />
                      </MinistryProvider>
                    </Suspense>
                  </Route>
                  <Route path="/campus-calendar/:slug?" exact>
                    <Suspense fallback={<PageLoader />}>
                      <MinistryProvider>
                        <CampusCalendar />
                      </MinistryProvider>
                    </Suspense>
                  </Route>
                  <Route
                    path="/:ministryCategory/:ministry/:ministrySubpage/:ministrySubSubpage"
                    exact
                  >
                    <Suspense fallback={<PageLoader />}>
                      <MinistryProvider>
                        <MinistrySubpage />
                      </MinistryProvider>
                    </Suspense>
                  </Route>
                  <Route
                    path="/:ministryCategory/:ministry/:ministrySubpage"
                    exact
                  >
                    <Suspense fallback={<PageLoader />}>
                      <MinistryProvider>
                        <MinistrySubpage />
                      </MinistryProvider>
                    </Suspense>
                  </Route>
                  <Route path="/:ministryCategory/:ministry" exact>
                    <Suspense fallback={<PageLoader />}>
                      <MinistryProvider>
                        <Ministry />
                      </MinistryProvider>
                    </Suspense>
                  </Route>
                  <Route path="/:ministryCategory" exact>
                    <Suspense fallback={<PageLoader />}>
                      <MinistryProvider>
                        <MinistryCategory />
                      </MinistryProvider>
                    </Suspense>
                  </Route>
                  <Route path="/" exact>
                    <Suspense fallback={<PageLoader />}>
                      <MinistryProvider>
                        <Home />
                      </MinistryProvider>
                    </Suspense>
                  </Route>
                  <Route
                    exact
                    path="/admin"
                    component={() => {
                      window.location.href = settings.backendURL;
                      return null;
                    }}
                  />
                  <Redirect to={settings.notFoundPath} />
                </Switch>
              </AnimatePresence>
            </main>
            <MinistryProvider>
              <Footer />
            </MinistryProvider>
          </div>
        </HeaderProvider>
      </QueryClientProvider>
    </Security>
  );
}
export default App;
