import React from "react";
import { Link, useLocation } from "react-router-dom";
import classNames from "classnames";
import Search from "./Search";
import Breadcrumb from "./Breadcrumb";
import HeaderMobileNav from "@/components/Header/HeaderMobileNav";
import HeaderNav from "@/components/Header/HeaderNav";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useComponentVisible from "@/hooks/useComponentVisible";
import Logo from "@/components/Header/Logo";
import SearchOverlay from "@/components/Search/SearchOverlay";
import useBreakpoints from "@/hooks/useBreakpoints";
import Errors from "@/components/Header/Errors";
import ReactGA from "react-ga4";
import { MinistryContext } from "@/context/MinistryContext";
import ContentLoader from "react-content-loader";

function Header({ className }) {
  const location = useLocation();
  const { breakpoint } = useBreakpoints();
  const { allDataLoaded } = React.useContext(MinistryContext);

  // Visibility settings for mobile nav
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  // Visibility settings for mobile search
  const {
    isComponentVisible: isSearchOverlayVisible,
    setIsComponentVisible: setIsSearchOverlayVisible,
  } = useComponentVisible(false);

  // Google Analytics Tracking
  React.useEffect(() => {
    ReactGA.send("pageview");
  }, [location]);

  React.useEffect(() => {
    const body = document.querySelector("body");
    return isComponentVisible && breakpoint.isMdDown
      ? body.classList.add("overflow-hidden")
      : body.classList.remove("overflow-hidden");
  }, [isComponentVisible, breakpoint]);

  return (
    <>
      <div data-cy="header" className="relative z-40 select-none">
        <Errors />
        <header ref={ref} className={classNames("relative block", className)}>
          <div className="relative bg-white flex items-center justify-center lg:justify-between w-full py-2 lg:py-6 px-6 lg:px-8 z-20">
            <div
              className="logo mr-6 lg:mr-12 order-first"
              onClick={() => setIsComponentVisible(false)}
            >
              <Link to="/" className="block">
                <Logo />
              </Link>
            </div>
            {breakpoint.isLgUp ? (
              // Desktop Nav
              <>
                <div className="flex-grow">
                  <HeaderNav />
                </div>
                {allDataLoaded ? (
                  <Search
                    onFocus={() => setIsComponentVisible(false)}
                    className="order-2 lg:order-3"
                  />
                ) : (
                  <ContentLoader
                    speed={1}
                    width={200}
                    height={36}
                    viewBox="0 0 200 36"
                    backgroundColor="var(--color-gray-100)"
                    foregroundColor="var(--color-white)"
                  >
                    <rect x="0" y="0" rx="5" ry="5" width="200" height="36" />
                  </ContentLoader>
                )}
              </>
            ) : (
              // Mobile Nav
              <div className="absolute text-blue-500 text-2xl flex left-0 px-6 gap-4">
                {allDataLoaded ? (
                  <button
                    className="w-6 flex items-center justify-center"
                    onClick={() => setIsComponentVisible(!isComponentVisible)}
                  >
                    <FontAwesomeIcon
                      icon={
                        isComponentVisible ? ["far", "times"] : ["fas", "bars"]
                      }
                    />
                  </button>
                ) : (
                  <ContentLoader
                    speed={1}
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    backgroundColor="var(--color-gray-100)"
                    foregroundColor="var(--color-white)"
                  >
                    <rect x="0" y="0" rx="5" ry="5" width="24" height="24" />
                  </ContentLoader>
                )}
                {allDataLoaded ? (
                    <Link to="/search" className="block">
                      <FontAwesomeIcon icon={["far", "search"]} />
                    </Link>
                ) : (
                  <ContentLoader
                    speed={1}
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    backgroundColor="var(--color-gray-100)"
                    foregroundColor="var(--color-white)"
                  >
                    <rect x="0" y="0" rx="5" ry="5" width="24" height="24" />
                  </ContentLoader>
                )}
              </div>
            )}
          </div>

          {breakpoint.isMdDown && (
            <HeaderMobileNav
              isOpen={isComponentVisible}
              setIsOpen={setIsComponentVisible}
            />
          )}
          <SearchOverlay
            isOpen={isSearchOverlayVisible}
            setIsOpen={setIsSearchOverlayVisible}
          />
        </header>
        <Breadcrumb />
      </div>
    </>
  );
}

export default React.memo(Header);
