import React, { useContext } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { HeaderContext } from "@/context/HeaderContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Breadcrumb({ className }) {
  const { breadcrumb } = useContext(HeaderContext);

  return (
    <>
      {breadcrumb && breadcrumb.length > 1 && (
        <div
          className={classNames(
            "bg-lightBlue-200 border-t border-b border-lightBlue-300 text-blue-500 text-xs whitespace-nowrap overflow-x-auto",
            className
          )}
        >
          <ul
            data-cy="breadcrumbs"
            className="flex items-center py-2 px-6 lg:px-8"
          >
            {breadcrumb.map((b, i) => (
              <li key={i} className="pr-2 lg:pr-4">
                {breadcrumb.length > i + 1 ? (
                  <span className="text-lightBlue-600">
                    <Link to={b.link}>
                      <span>{b.title}</span>
                    </Link>
                    <FontAwesomeIcon
                      className="ml-2 lg:ml-4 text-xs"
                      icon={["far", "chevron-right"]}
                    />
                  </span>
                ) : (
                  <span>{b.title}</span>
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
}

export default React.memo(Breadcrumb);
