import React from "react";
import Loader from "@/components/Loader";

function PageLoader() {
  return (
    <div className="h-96 flex w-full items-center justify-center">
      <Loader />
    </div>
  );
}

export default PageLoader;
