import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faAngleRight,
  faAngleLeft,
  faLongArrowRight,
} from "@fortawesome/pro-light-svg-icons";
import {
  faClock,
  faCalendarClock,
  faCalendarRange,
  faStopwatch,
  faCalendar,
  faGear,
  faChevronRight,
  faChevronLeft,
  faTimes,
  faSearch,
} from "@fortawesome/pro-regular-svg-icons";
import {
  faChevronDown,
  faChevronUp,
  faPlus,
  faMinus,
  faBars,
  faFileDownload,
  faEye,
  faEyeSlash,
  faLink,
  faCheck,
} from "@fortawesome/pro-solid-svg-icons";

library.add(
  faChevronDown,
  faChevronUp,
  faSearch,
  faClock,
  faCalendarClock,
  faCalendarRange,
  faStopwatch,
  faCalendar,
  faGear,
  faChevronRight,
  faChevronLeft,
  faAngleRight,
  faAngleLeft,
  faPlus,
  faMinus,
  faBars,
  faTimes,
  faLongArrowRight,
  faFileDownload,
  faEye,
  faEyeSlash,
  faLink,
  faCheck
);
