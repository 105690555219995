import React from "react";
import classNames from "classnames";
import ContentLoader from "react-content-loader";
import { css } from "@emotion/css";
import { NavLink } from "react-router-dom";
import { MinistryContext } from "@/context/MinistryContext";

const HeaderNav = function ({ onNavHover, onNavUnhover }) {
  const { allDataLoaded, allMinistryCategoryData } = React.useContext(
    MinistryContext
  );
  const [navScrimVisible, setNavScrimVisible] = React.useState(false);

  React.useEffect(() => {
    const body = document.querySelector("body");
    return navScrimVisible
      ? body.classList.add("overflow-y-hidden")
      : body.classList.remove("overflow-y-hidden");
  }, [navScrimVisible]);

  return (
    <>
      <div
        data-cy="header-nav"
        className={classNames(
          "nav-scrim fixed left-0 w-full h-full bottom-0 bg-gray-500 bg-opacity-20 z-40 transition delay-150 duration-500 backdrop-filter backdrop-blur-sm",
          css`
            height: calc(100vh - 98px);
            top: 98px;
            opacity: ${navScrimVisible ? 1 : 0};
            pointer-events: ${navScrimVisible ? "auto" : "none"};
          `
        )}
      />
      <nav
        data-cy="header-nav"
        className={classNames(
          "text-blue-500",
          css`
            .header-nav__topLevel {
              & > strong a:before {
                content: "";
                position: absolute;
                top: 0;
                left: 50%;
                width: 0;
                height: 100%;
                background-color: var(--color-gray-100);
                border-radius: 0.5rem;
                transition: width 0.3s ease, opacity 0.3s ease;
                will-change: width;
                opacity: 0;
                transform: translateX(-50%);
                z-index: -1;
              }

              & > strong a.active:before,
              &:hover > strong a:before {
                width: 100%;
                opacity: 1;
              }
            }
          `
        )}
      >
        {allDataLoaded ? (
          <ul
            className="w-auto inline-flex mr-auto"
            onMouseEnter={() => setNavScrimVisible(true)}
            onMouseLeave={() => setNavScrimVisible(false)}
          >
            {allMinistryCategoryData.data.map((m, i) => (
              <li
                key={i}
                className={classNames(
                  "group relative header-nav__topLevel mr-4",
                  css`
                    --ministryColor: ${m.color_code};
                  `
                )}
              >
                <strong className="relative inline-block mb-1 font-medium text-blue-500 text-2xl lg:text-sm">
                  <NavLink
                    className="inline-block relative z-10 py-2 px-4"
                    activeClassName="active"
                    to={m.link}
                  >
                    {m.title}
                  </NavLink>
                </strong>
                <div
                  className={classNames(
                    "absolute top-full transform transition translate-x-16 opacity-0 group-hover:opacity-100 group-hover:-translate-x-4 text-black pointer-events-none group-hover:pointer-events-auto z-50 min-w-max",
                    css`
                      min-width: 350px;
                    `
                  )}
                >
                  <ul className="rounded-md shadow-lg py-6 px-8 bg-white mt-8 leading-tight">
                    <li className="transition transform hover:translate-x-2 mb-3">
                      <strong className="font-bold text-blue-500  text-ministry block">
                        <NavLink
                          exact={true}
                          activeClassName="border-b-2 border-ministry active"
                          to={m.link}
                          className="inline-block"
                        >
                          {m.title}
                        </NavLink>
                      </strong>
                    </li>
                    {m.ministries.map((mm, ii) => (
                      <li
                        key={ii}
                        className="transition transform hover:translate-x-2 mb-3"
                      >
                        <NavLink
                          exact={true}
                          activeClassName="border-b-2 border-ministry active"
                          to={mm.link}
                          className="inline-block"
                        >
                          {mm.title}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <ul className="w-full flex" data-cy="header-nav-leader">
            {[0, 1, 2, 3, 4].map((i) => (
              <li className="mr-8" key={i}>
                <ContentLoader
                  speed={1}
                  width={80}
                  height={13}
                  viewBox="0 0 80 13"
                  backgroundColor="var(--color-gray-100)"
                  foregroundColor="var(--color-white)"
                >
                  <rect x="0" y="0" rx="5" ry="5" width="80" height="13" />
                </ContentLoader>
              </li>
            ))}
          </ul>
        )}
      </nav>
    </>
  );
};

export default React.memo(HeaderNav);
