import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import "./index.css";
import "@/lib/fontawesome";
import settings from "@/settings";
import App from "@/App";
import ReactGA from "react-ga4";
import reportWebVitals from "./reportWebVitals";

// Initialize Google Analytics
ReactGA.initialize(settings.googleAnalyticsID);

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
