import React, { useState, createContext } from "react";
export const HeaderContext = createContext();

export const HeaderProvider = ({ children }) => {
  const [pageTitle, setPageTitle] = useState("Default");
  const [breadcrumb, setBreadcrumb] = useState(false);
  const [errors, setErrors] = useState(false);

  const buildBreadcrumb = (currentLocation) => {
    const bc = [
      {
        title: "Home",
        link: "/",
      },
    ];
    currentLocation.map((c) => bc.push(c));
    return bc;
  };

  return (
    <HeaderContext.Provider
      value={{
        pageTitle,
        setPageTitle,
        breadcrumb,
        setBreadcrumb,
        buildBreadcrumb,
        errors,
        setErrors,
      }}
    >
      {children}
    </HeaderContext.Provider>
  );
};
