import React from "react";
import { InstantSearch } from "react-instantsearch-dom";
import algoliasearch from "algoliasearch/lite";

const searchClient = algoliasearch(
  "TUGZ383SI1",
  "1fcb038e282e8efe758fcda5ec6f3941"
);

function Algolia({ children }) {
  return (
    <InstantSearch indexName="playbook_ministries" searchClient={searchClient}>
      {children}
    </InstantSearch>
  );
}

export default Algolia;
