import React from "react";
import { css } from "@emotion/css";
import { MinistryContext } from "@/context/MinistryContext";
import classNames from "classnames";

function Footer() {
  const { allDataLoaded, allMinistryCategoryData } = React.useContext(
    MinistryContext
  );
  const gradient = React.useMemo(() => {
    if (!allDataLoaded) return "#F3D54E,#69B3E7,#F8AEA8,#002A54,#7E93AC";
    return allMinistryCategoryData.data.map((d) => d.color_code).join(",");
  }, [allMinistryCategoryData, allDataLoaded]);
  return (
    <footer
      className={classNames(
        "relative text-sm text-blue-500 bg-gray-100 p-6 mt-16",
        css`
          &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 4px;
            background: linear-gradient(to right, ${gradient});
          }
        `
      )}
    >
      <div className="container">
        &copy; {new Date().getFullYear()}{" "}
        <a href="https://gatewaypeople.com">Gateway Church</a>. All rights
        reserved.
      </div>
    </footer>
  );
}

export default React.memo(Footer);
