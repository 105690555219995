import React from "react";
import classNames from "classnames";
import { HeaderContext } from "@/context/HeaderContext";

function Errors() {
  const { errors } = React.useContext(HeaderContext);

  return (
    <div
      className={classNames(
        "absolute z-50 bg-red-500 flex justify-center text-white px-4 py-2 font-bold transform left-1/2 top-full -translate-x-1/2 -translate-y-1/2 rounded-lg shadow-lg transition pointer-events-none delay-500 duration-1000",
        {
          "opacity-0 -translate-y-full": !errors,
        }
      )}
    >
      {errors.message}
    </div>
  );
}

export default Errors;
