import React from "react";
import { css } from "@emotion/css";
import classNames from "classnames";
import ContentLoader from "react-content-loader";
import useBreakpoints from "@/hooks/useBreakpoints";
function Loader({
  type,
  color,
  className,
  foregroundColor,
  backgroundColor,
  "data-cy": dataCy,
}) {
  const { breakpoint } = useBreakpoints();
  const spinner = React.useMemo(() => {
    let dimensions;
    switch (type) {
      case "dots":
        return (
          <div
            className={classNames(
              "flex items-center justify-center transform scale-50",
              className
            )}
          >
            <div
              data-cy={dataCy}
              className={
                ("",
                css`
                  margin: 0;
                  width: 70px;

                  & > div {
                    width: 18px;
                    height: 18px;
                    background-color: ${color};
                    border-radius: 100%;
                    display: inline-block;
                    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out
                      both;
                    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
                  }

                  .bounce1 {
                    -webkit-animation-delay: -0.32s;
                    animation-delay: -0.32s;
                  }

                  .bounce2 {
                    -webkit-animation-delay: -0.16s;
                    animation-delay: -0.16s;
                  }

                  @-webkit-keyframes sk-bouncedelay {
                    0%,
                    80%,
                    100% {
                      -webkit-transform: scale(0);
                    }
                    40% {
                      -webkit-transform: scale(1);
                    }
                  }

                  @keyframes sk-bouncedelay {
                    0%,
                    80%,
                    100% {
                      -webkit-transform: scale(0);
                      transform: scale(0);
                    }
                    40% {
                      -webkit-transform: scale(1);
                      transform: scale(1);
                    }
                  }
                `)
              }
            >
              <div className="bounce1"></div>
              <div className="bounce2"></div>
              <div className="bounce3"></div>
            </div>
          </div>
        );
      case "pageLoader":
        return null;
      case "card":
        return (
          <ContentLoader
            speed={2}
            width={300}
            height={200}
            viewBox="0 0 300 200"
            backgroundColor={backgroundColor}
            foregroundColor={foregroundColor}
            style={{ width: "100%" }}
          >
            <rect x="0" y="0" rx="5" ry="5" width="300" height="200" />
          </ContentLoader>
        );
      case "calendar":
        return (
          <ContentLoader
            speed={2}
            width={80}
            height={100}
            viewBox="0 0 80 100"
            backgroundColor={backgroundColor}
            foregroundColor={foregroundColor}
            style={{ width: "100%" }}
          >
            <circle cx="40" cy="40" r="30" />
            <rect x="0" y="80" rx="5" ry="5" width="800" height="15" />
          </ContentLoader>
        );
      case "ministryCategory":
        dimensions = {
          hero: {
            width: 1200,
            height: 300,
          },
          title: {
            width: breakpoint.isLgUp ? 500 : 300,
            height: breakpoint.isLgUp ? 50 : 30,
          },
        };
        return (
          <div className="container mx-auto px-6">
            <div className="my-6">
              <ContentLoader
                speed={2}
                width={dimensions.hero.width}
                height={dimensions.hero.height}
                viewBox={`0 0 ${dimensions.hero.width} ${dimensions.hero.height}`}
                backgroundColor={backgroundColor}
                foregroundColor={foregroundColor}
                style={{ width: "100%", height: "auto" }}
              >
                <rect
                  x="0"
                  y="0"
                  rx="5"
                  ry="5"
                  width={dimensions.hero.width}
                  height={dimensions.hero.height}
                />
              </ContentLoader>
            </div>

            <div className="mb-6 lg:mb-16">
              <ContentLoader
                speed={2}
                width={dimensions.title.width}
                height={dimensions.title.height}
                viewBox={`0 0 ${dimensions.title.width} ${dimensions.title.height}`}
                backgroundColor={backgroundColor}
                foregroundColor={foregroundColor}
                style={{ maxWidth: "100%", height: "auto" }}
              >
                <rect
                  x="0"
                  y="0"
                  rx="5"
                  ry="5"
                  width={dimensions.title.width}
                  height={dimensions.title.height}
                />
              </ContentLoader>
            </div>

            <div className="grid lg:grid-cols-2 gap-4">
              {[1, 2, 3, 4].map((i) => (
                <ContentLoader
                  key={i}
                  speed={2}
                  width={600}
                  height={100}
                  viewBox="0 0 600 100"
                  backgroundColor={backgroundColor}
                  foregroundColor={foregroundColor}
                  style={{ width: "100%", height: "auto" }}
                >
                  <rect x="0" y="0" rx="5" ry="5" width="600" height="100" />
                </ContentLoader>
              ))}
            </div>
          </div>
        );
      case "ministry":
        dimensions = {
          hero: {
            width: 1200,
            height: 300,
          },
          title: {
            width: breakpoint.isLgUp ? 500 : 300,
            height: breakpoint.isLgUp ? 50 : 30,
          },
          sidebar: {
            width: 500,
            height: 600,
          },
        };
        return (
          <div className="container mx-auto px-6 my-16">
            <div
              className={classNames(
                "grid lg:grid-cols-4 gap-4 lg:mb-16 lg:gap-16"
              )}
            >
              <div className="lg:col-span-1 mb-16 lg:mb-0">
                <div className="grid grid-cols-1 gap-4">
                  {[1, 2, 3, 4].map((i) => (
                    <ContentLoader
                      key={i}
                      speed={2}
                      width={600}
                      height={80}
                      viewBox="0 0 600 80"
                      backgroundColor={backgroundColor}
                      foregroundColor={foregroundColor}
                      style={{ width: "100%", height: "auto" }}
                    >
                      <rect x="0" y="0" rx="5" ry="5" width="600" height="80" />
                    </ContentLoader>
                  ))}
                </div>
              </div>
              <div className="lg:col-span-2 mb-16 lg:mb-0">
                <div className="mb-2">
                  <ContentLoader
                    speed={2}
                    width={dimensions.title.width / 2}
                    height={dimensions.title.height / 2}
                    viewBox={`0 0 ${dimensions.title.width / 2} ${
                      dimensions.title.height / 2
                    }`}
                    backgroundColor={backgroundColor}
                    foregroundColor={foregroundColor}
                    style={{ maxWidth: "100%", height: "auto" }}
                  >
                    <rect
                      x="0"
                      y="0"
                      rx="5"
                      ry="5"
                      width={dimensions.title.width / 2}
                      height={dimensions.title.height / 2}
                    />
                  </ContentLoader>
                </div>

                <div className="mb-6 lg:mb-16">
                  <ContentLoader
                    speed={2}
                    width={dimensions.title.width}
                    height={dimensions.title.height}
                    viewBox={`0 0 ${dimensions.title.width} ${dimensions.title.height}`}
                    backgroundColor={backgroundColor}
                    foregroundColor={foregroundColor}
                    style={{ maxWidth: "100%", height: "auto" }}
                  >
                    <rect
                      x="0"
                      y="0"
                      rx="5"
                      ry="5"
                      width={dimensions.title.width}
                      height={dimensions.title.height}
                    />
                  </ContentLoader>
                </div>

                <div className="grid grid-cols-1 gap-4">
                  {[1, 2, 3, 4].map((i) => (
                    <ContentLoader
                      key={i}
                      speed={2}
                      width={600}
                      height={80}
                      viewBox="0 0 600 80"
                      backgroundColor={backgroundColor}
                      foregroundColor={foregroundColor}
                      style={{ width: "100%", height: "auto" }}
                    >
                      <rect x="0" y="0" rx="5" ry="5" width="600" height="80" />
                    </ContentLoader>
                  ))}
                </div>
              </div>

              <div className="mb-16 lg:mb-0 lg:col-span-1">
                <div className="mb-6">
                  <ContentLoader
                    speed={2}
                    width={dimensions.sidebar.width}
                    height={dimensions.sidebar.height}
                    viewBox={`0 0 ${dimensions.sidebar.width} ${dimensions.sidebar.height}`}
                    backgroundColor={backgroundColor}
                    foregroundColor={foregroundColor}
                    style={{ width: "100%", height: "auto" }}
                  >
                    <rect
                      x="0"
                      y="0"
                      rx="5"
                      ry="5"
                      width={dimensions.sidebar.width}
                      height={dimensions.sidebar.height}
                    />
                  </ContentLoader>
                </div>
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  }, [
    type,
    className,
    breakpoint,
    backgroundColor,
    color,
    dataCy,
    foregroundColor,
  ]);

  return spinner;
}

Loader.defaultProps = {
  type: "dots",
  color: "var(--color-gray-500)",
  foregroundColor: "var(--color-gray-50)",
  backgroundColor: "var(--color-gray-100)",
};

export default Loader;
