import React from "react";
import Algolia from "@/lib/Algolia";
import { useHistory } from "react-router-dom";
import {
  Hits,
  SearchBox,
  Configure,
  connectStateResults,
} from "react-instantsearch-dom";
import SearchResult from "@/components/Search/SearchResult";
import useComponentVisible from "@/hooks/useComponentVisible";

function Search({ className, onFocus }) {
  let history = useHistory();
  const {
    ref,
    isComponentVisible,
    setIsComponentVisible,
  } = useComponentVisible(false);

  const searchWatcher = (e) => {
    if (e.target && "value" in e.target && e.target.value.length >= 2) {
      setIsComponentVisible(true);
    } else {
      setIsComponentVisible(false);
    }
  };

  const Results = connectStateResults(({ searchResults }) =>
    searchResults.nbHits > 0 ? (
      <Hits hitComponent={SearchResult} />
    ) : (
      <div className="px-6 py-2">Nothing found...</div>
    )
  );

  return (
    <div ref={ref} className="relative">
      <Algolia>
        <Configure hitsPerPage={10} />
        <SearchBox
          translations={{
            placeholder: "Search",
          }}
          onKeyUp={searchWatcher}
          onSubmit={event => {
            event.preventDefault();
            const searchInput = event.target.querySelector(".ais-SearchBox-input");
            searchInput.value === null ? history.push(`/search/ministry`) : history.push(`/search/ ${searchInput.value}`);
            setTimeout(() => {
              setIsComponentVisible(false)
            }, 500);
          }}
          autoFocus={false}
        />
        {isComponentVisible && (
          <div
            data-cy="search-results"
            className="absolute shadow-xl bg-white rounded-md text-sm py-2 right-0"
            style={{ width: "350px" }}
          >
            <Results />
          </div>
        )}
      </Algolia>
    </div>
  );
}

export default React.memo(Search);
